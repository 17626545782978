import React, { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ErrorIcon from '@mui/icons-material/Error';

import InputField from "../InputField.js";

function PasswordReset(props){
    const [loading, setLoadingStatus] = useState(false);
    const [requestError, setRequestError] = useState(false);
    const [updatePrompt, setUpdatePrompt] = useState("Update");
    const session = props?.session;
    const requirements = [
        { key: "capitalLetter", label: "1 Capital Letter" },
        { key: "lowerCase", label: "1 Lowercase" },
        { key: "symbol", label: "1 Symbol" },
        { key: "number", label: "1 Number" },
        { key: "length", label: "At least 8 Characters" },
        { key: "matchingPasswords", label: "Matching Passwords" },
    ];
    const [data, setData] = useState({
        currPassword : '',
        password : '',
        passwordConfirmation : ''
    });

    function eligibility(attrs) {
        for (const attr of attrs) {
            if (data[attr] === '') {
                return false;
            }
        }

        return true;
    }

    function passwordRequirements() {
        return (
            <div className="prompt loginReqs alt f cC g">
                <div className="f cC bold">
                    Password must include:
                </div>
                {requirements.map((requirement) => (
                    <div className="g f cL fR" key={requirement.key}>
                        <div className={`f cC${requirementStatus(requirement.key) ? " complete" : ''}`}>
                            {requirementStatus(requirement?.key) ?
                                <CheckRoundedIcon />
                                :
                                <HighlightOffRoundedIcon />
                            }
                        </div>
                        <div className="f s cL">
                            {requirement?.label}
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    function requirementStatus(req) {
        const currPassword = data?.currPassword || '';
        const password = data?.password || '';
        const passwordConfirmation = data?.passwordConfirmation || '';
      
        const checks = {
            length: password.length >= 8,
            capitalLetter: /[A-Z]/.test(password),
            lowerCase: /[a-z]/.test(password),
            symbol: /[\W_]/.test(password),
            number: /\d/.test(password),
            matchingPasswords: password !== '' && password === passwordConfirmation,
        };
      
        if (req === "all") {
            return (
                checks.length &&
                checks.capitalLetter &&
                checks.lowerCase &&
                checks.symbol &&
                checks.number &&
                checks.matchingPasswords
            );
        }
      
        if (req.includes("length") && !checks.length) {
            return false;
        }
      
        if (req.includes("capitalLetter") && !checks.capitalLetter) {
            return false;
        }
      
        if (req.includes("lowerCase") && !checks.lowerCase) {
            return false;
        }
      
        if (req.includes("symbol") && !checks.symbol) {
            return false;
        }
      
        if (req.includes("number") && !checks.number) {
            return false;
        }
      
        if (req.includes("matchingPasswords") && !checks.matchingPasswords) {
            return false;
        }
      
        return true;
    }

    function changePassword(){
        setLoadingStatus(true);
        //console.log(session?.user?.data);
        const paramVals = {
            "userID" : session?.user?.data?.userID,
            "currPassword" : data?.currPassword,
            "password" : data?.password
        };
      
        session?.env?.functions?.buildFetchRequest("user/update", paramVals)
        .then(response => response.json())
        .then(resData => {
            setLoadingStatus(false);

            if (resData.status === 200) {
                setRequestError(false);
                session?.env?.setOverlay();
            }else{
                setRequestError(true);
                setUpdatePrompt("Update Error");
            }
        });
    }

    useEffect(() => {
        if(session?.env?.overlay !== "passwordChange"){
            setData({
                password : '',
                passwordConfirmation : ''
            })
        }
    }, [session?.env?.overlay]);

    return (
        <>
            <div className="passwordForm g f oH dP">
                <div className="form g f dG cC">
                    <InputField
                        value={data?.currPassword}
                        isPassword={true}
                        functionPointer={session?.env?.functions?.updateInput}
                        placeholder="Current Password"
                        name="currPassword"
                        setter={setData}
                    />
                    <InputField
                        value={data?.password}
                        isPassword={true}
                        functionPointer={session?.env?.functions?.updateInput}
                        placeholder="Password"
                        name="password"
                        setter={setData}
                    />
                    <InputField
                        value={data?.passwordConfirmation}
                        isPassword={true}
                        functionPointer={session?.env?.functions?.updateInput}
                        placeholder="Confirm Password"
                        name="passwordConfirmation"
                        setter={setData}
                    />
                    {passwordRequirements()}
                </div>
                <div className="f fC fR dP">
                    <div
                        className={`${loading ? "btn" : "btnWIcon"}${requestError ? " error" : ''} f g bR e cC bold nS${(eligibility(["password", "passwordConfirmation"]) &&
                            data?.currPassword) &&
                            requirementStatus("all") ? " active p" : " inactive"}`
                        }
                        onClick={eligibility(["password", "passwordConfirmation"]) && requirementStatus("all") && data?.currPassword ? ()=>{changePassword()} : null}
                    >
                        <div className="f cC">
                            {loading ?
                                <CircularProgress/>
                            :
                                updatePrompt
                            }
                        </div>
                        {!loading &&
                            <div className="f cC">
                                {requestError ?
                                    <ErrorIcon/>
                                :
                                    <VerifiedRoundedIcon/>
                                }
                            </div>     
                        }
                    </div>
                </div>
            </div>
        </>
    )
};

export default PasswordReset;
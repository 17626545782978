import React, { useState, useEffect, useRef } from 'react';


import InputField from "../Components/InputField.js";
import ViewBarBtn from '../Components/ViewBarBtn.js';
import Dropdown from '../Components/Dropdown';

import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import VerifiedIcon from '@mui/icons-material/Verified';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import CircularProgress from '@mui/material/CircularProgress';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PushPinIcon from '@mui/icons-material/PushPin';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoIcon from '@mui/icons-material/Info';
import LinkIcon from '@mui/icons-material/Link';
import ArticleIcon from '@mui/icons-material/Article';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import LanguageIcon from '@mui/icons-material/Language';


function ResearchHub(props){
    
    const session = props?.session;
    const reporting = session?.reporting;
    const currentAccountID = session?.handler?.data?.currentAccountID;
    const individualData = session?.individual?.data[session?.handler?.data?.currentAccountID];
    const viewType = session?.env?.viewport?.viewType;

    const [displayInvalid, setDisplayInvalid] = useState(false);
    const [invalidInputs, setInvalidInputs] = useState([]);

    const [obitTabSelected, setObitTabSelected] = useState(session?.account?.data[currentAccountID]?.permissableUse ? false : true);
    const [deathSearched, setDeathSearched] = useState(false);
    const [obitSearched, setObitSearched] = useState(false);
    const [deathLoading, setDeathLoading] = useState(false);
    const [obitLoading, setObitLoading] = useState(false);
    const [obitSearchAttempted, setObitSearchAttempted] = useState(false);
  
    const [selectedStateObit, setSelectedStateObit] = useState({ showMenu: false });
    const [selectedState, setSelectedState] = useState({
        showMenu : false
    });

    const [deathResData, setDeathResData] = useState({});
    const [obitResData, setObitResData] = useState({});

    const [currentObitRow, setCurrentObitRow] = useState(0);

    const obitString = useRef(null);

    const [data, setData] = useState({
        first_name: '',
        middle_name: '',
        last_name: '',
        ssn: '',
        dob: '',
        state: '',
    })

    const requiredFilled = () =>{
        if(obitTabSelected){
            if(data?.first_name !== '' && data?.last_name !== '' && data?.dob !== ''){
                return true;
            }else{
                return false;
            }
        }
        else if(!obitTabSelected){
            if(data?.first_name !== '' && data?.last_name !== '' && data?.dob !== '' && data?.ssn !== ''){
                return true;
            }else{
                return false;
            }                                             
        }                     
    }                                                                                 
                                                                                                                         
    const validateInputs = () => {
        let validInput = true;
        let invalidInputsArr = [];
        // Allow SSNs with or without dashes
        if (!obitTabSelected && !(/^\d{9}$|^\d{3}-\d{2}-\d{4}$/.test(data?.ssn))) {
            validInput = false;
            invalidInputsArr.push("ssn");
        }
        if (!/^[A-Za-z-' ]+$/.test(data?.first_name)) {
            validInput = false;
            invalidInputsArr.push("first_name");
        }
        if (!/^[A-Za-z-' ]+$/.test(data?.last_name)) {
            validInput = false;
            invalidInputsArr.push("last_name");
        }
        setInvalidInputs(invalidInputsArr);
        return validInput;
    }

    const getHostName = url => {
        try {
            return new URL(url).hostname;
        } catch (e) {
            return undefined;
        }
    };

    const resultData = [
        { icon: <PersonIcon />, label: 'Name:', value: 'John M. Smith' },
        { icon: <VerifiedIcon />, label: 'Source:', value: 'Obituary' },
        { icon: <AssuredWorkloadIcon />, label: 'DOD:', value: '04/23/2014' },
        { icon: <FingerprintIcon />, label: 'SSN:', value: '***-***-4153' },
        { icon: <CalendarMonthIcon />, label: 'DOB:', value: '03/12/1945' }, 
    ];
    const obitResultData = [
        { icon: <PersonIcon />, label: 'Name:', value: 'John M. Smith' },
        { icon: <AssuredWorkloadIcon />, label: 'DOD:', value: '04/23/2014' },
        { icon: <CalendarMonthIcon />, label: 'DOB:', value: '03/12/1945' },
        { icon: <LocationCityIcon />, label: 'City:', value: 'Orlando' },
        { icon: <LocationOnIcon />, label: 'State:', value: 'Florida' },
        { icon: <PushPinIcon />, label: 'Zip:', value: '32321' },
    ];

    useEffect(() => {
        const approvedAttributes = ["city", "dob", "dod", "first_name", "last_name", "middle_name", "state", "zip_code"];

        if (obitString.current && obitResData[currentObitRow]?.text_content) {
            // Normalize whitespace in obitStringHTML
            let obitStringHTML = obitResData[currentObitRow]?.text_content.replace(/\s+/g, ' ');

            if (obitResData[currentObitRow]?.dob && obitResData[currentObitRow]?.dod) {
                const age = calculateAge(obitResData[currentObitRow]?.dob, obitResData[currentObitRow]?.dod).toString();
                // Enhanced pattern to include a lookbehind for a space or start of line, and a lookahead for a space, end of line, or non-numeric character
                const agePattern = new RegExp(`(?<=^|\\s)${escapeRegExp(age)}(?=$|\\s|\\D)`, 'gi');
    
                if (agePattern.test(obitStringHTML)) {
                    obitStringHTML = obitStringHTML.replace(agePattern, `<span class="matchingText bR bold s e">${age}</span>`);
                }
            }

            approvedAttributes.forEach(key => {
                let values = [obitResData[currentObitRow]?.[key]];
                // console.log(values);
                // Check if the attribute is a date
                if (key === 'dob' || key === 'dod') {
                    values = formatDateVariations(obitResData[currentObitRow]?.[key]);
                }

                values.forEach(value => {
                    if (value && typeof value === 'string') {
                        const normalizedValue = value.replace(/\s+/g, ' ');
                        // Use word boundaries (\b) around the normalized value to match only full words
                        let pattern;
                        if(key === 'state'){
                            pattern = new RegExp(`\\b${escapeRegExp(normalizedValue)}\\b`, 'g');
                        }else{
                            pattern = new RegExp(`\\b${escapeRegExp(normalizedValue)}\\b`, 'gi');
                        }
                        // Replace the matched value with bold tags
                        if (pattern.test(obitStringHTML)) { // Test if the pattern exists in the obitStringHTML before replacing
                            if(key === 'state' && value.length === 2 && value !== value.toUpperCase()){                          
                            }else{
                                obitStringHTML = obitStringHTML.replace(pattern, `<span class="matchingText bR bold s e">${normalizedValue}</span>`);
                            }                    
                        }
                    }
                });
            });

            obitString.current.innerHTML = `<section>${obitStringHTML}</section>`;
        }
    }, [obitSearched, currentObitRow, obitLoading, obitTabSelected]); 

    function calculateAge(dob, dod = null) {
        const birthDate = new Date(dob);
        const endDate = dod ? new Date(dod) : new Date(); // Use the provided date of death or the current date if dod is not provided
        let age = endDate.getFullYear() - birthDate.getFullYear();
        const monthDifference = endDate.getMonth() - birthDate.getMonth();
    
        if (monthDifference < 0 || (monthDifference === 0 && endDate.getDate() < birthDate.getDate())) {
            age--;
        }
    
        return age;
    }

    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    const formatDateVariations = (dateString) => {
        if (!dateString || !dateString.includes('-')) {
            return [];
        }

        const [year, month, day] = dateString.split("-").map(Number);
        const date = new Date(year, month - 1, day);
    
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const longDate = date.toLocaleDateString('en-US', options);
    
        const shortYear = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().substr(-2)}`;
        const fullYear = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    
        return [longDate, shortYear, fullYear];
    };

    function deathSearch(){
        setDeathLoading(true);
        const sessionUserString = localStorage.getItem('sessionUser');
        let sessionUser = null;
        if (sessionUserString) {
            sessionUser = JSON.parse(sessionUserString);
        }
        const paramVals = {
            first_name: data?.first_name,
            middle_name: data?.middle_name,
            last_name: data?.last_name,
            dob: data?.dob,
            ssn: data?.ssn,
            state: data?.state,
            accountID: sessionUser.accountID
        };
        session?.env?.functions?.buildFetchRequest("search/records", paramVals, null, null, "POST")
          .then(response => response.json())
          .then(resData => {
            
            if(resData.status === 200){
                setDeathResData(resData?.result?.data);              
                setDeathLoading(false);
            }else{
                //setErrorStatus(resData.message);
            }
        });
    }

    function obitSearch(){
        setObitLoading(true);
        const sessionUserString = localStorage.getItem('sessionUser');
        let sessionUser = null;
        if (sessionUserString) {
            sessionUser = JSON.parse(sessionUserString);
        }
        const paramVals = {
            first_name: data?.first_name,
            middle_name: data?.middle_name,
            last_name: data?.last_name,
            dob: data?.dob,
            city: data?.city,
            state: data?.state,
            zip_code: data?.zip_code,
            accountID: sessionUser.accountID
        };
        session?.env?.functions?.buildFetchRequest("search/records/obit", paramVals, null, null, "POST")
          .then(response => response.json())
          .then(resData => {
            
            if(resData.status === 200){
                setObitResData(resData?.result?.data);
                setObitLoading(false);
            }else{
                if(resData.status === 500){
                    if(!obitSearchAttempted){
                        obitSearch();
                        setObitSearchAttempted(true);
                    }
                    
                }
            }
        });
    }

    useEffect(()=>{
        // console.log(deathResData);
    }, [deathResData]);

    return (
            <div className="researchHubContainer f g bR" >
                {/* <div className='researchHubBox f cC g bR dP'> */}
                <div className='researchHubTabs g gR1 cC'>
                    {session?.account?.data[currentAccountID]?.permissableUse ?
                        <div className="uploadViewBar f gC1 dP">                
                            <div className="gC1">
                                <ViewBarBtn
                                    isActive={!obitTabSelected}
                                    onClick={() => setObitTabSelected(false)}
                                    label="Death Record Hub"
                                    icon={<AssuredWorkloadIcon />}
                                    alertType={"Status"}
                                    // viewType={viewType}
                                    session={session}
                                    currentPolicy={props?.currentPolicy}
                                />
                            </div>                        
                            <div className="gC2">
                                <ViewBarBtn                                   
                                    isActive={obitTabSelected}
                                    onClick={() => setObitTabSelected(true)}
                                    label="Obituary Research Hub"
                                    icon={<NewspaperIcon/>}
                                    // viewType={viewType}
                                    session={session}
                                />
                            </div>         
                        </div>
                        :
                        <div className='bold'>
                            Obituary Research Hub
                        </div>
                    }
                </div>
                <div className='researchHubContent f gR2 g bR dP'>
                    <div className='researchHubInputs f g bR gR1'>  
                        <div className='cC gR1'>
                            <div className='inputTitle dP bR bold cC'>
                                {displayInvalid ? 
                                    <div className='cC dG'>
                                        <div>
                                            <InfoIcon style={{ color: 'red' }}/>
                                        </div>
                                        <div className='redText'>
                                            Fix invalid inputs to search
                                        </div>
                                    </div>
                                    :
                                    <div className='cC dG'>
                                        <div>
                                            <InfoIcon style={{ color: 'dimgray' }}/>
                                        </div>
                                        <div>
                                            Enter an Individual to Search
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                        <div className='cC gR2 dG dP f g inputRow1'>
                            <div className='gC2 f'>
                                {!obitTabSelected ?
                                    <InputField
                                        value={data?.first_name}
                                        placeholder={"First Name *"}
                                        functionPointer={session?.env?.functions?.updateInput}
                                        name="first_name"
                                        setter={setData}
                                        {...(displayInvalid && invalidInputs.includes('first_name') ? { error:' '} : {})}
                                    />   
                                    :
                                    <InputField
                                        value={data?.first_name}
                                        placeholder={"First Name *"}
                                        functionPointer={session?.env?.functions?.updateInput}
                                        name="first_name"
                                        setter={setData}
                                        {...(displayInvalid && invalidInputs.includes('first_name') ? { error:' '} : {})}
                                    />   
                                }
                            </div>
                            <div className='gC4 f'>
                                {!obitTabSelected ?
                                    <InputField
                                            value={data?.middle_name}
                                            placeholder={"Middle Name"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="middle_name"
                                            setter={setData}
                                    />
                                    :
                                    <InputField
                                            value={data?.middle_name}
                                            placeholder={"Middle Name"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="middle_name"
                                            setter={setData}
                                    />
                                }
                            </div>
                            <div className='gC6 f'>
                                {!obitTabSelected ?
                                    <InputField
                                        value={data?.last_name}
                                        placeholder={"Last Name *"}
                                        functionPointer={session?.env?.functions?.updateInput}
                                        name="last_name"
                                        setter={setData}
                                        {...(displayInvalid && invalidInputs.includes('last_name') ? { error:' '} : {})}
                                    />
                                    :
                                    <InputField
                                        value={data?.last_name}
                                        placeholder={"Last Name *"}
                                        functionPointer={session?.env?.functions?.updateInput}
                                        name="last_name"
                                        setter={setData}
                                        {...(displayInvalid && invalidInputs.includes('last_name') ? { error:' '} : {})}
                                    />
                                }
                            </div>
                        </div>
                        <div className={`bC gR3 dG dP g ${!obitTabSelected ? 'inputRow2' : 'obitInputRow2'}`}>
                            <div className='dobLabel C inputFont gC2'>{viewType ==="mobile" ? "DOB*" : "Date of Birth*" }</div>
                            <div className='gC3 f dobField'>
                                {!obitTabSelected ?
                                    <InputField                                        
                                        value={data?.dob}
                                        type="date" 
                                        functionPointer={session?.env?.functions?.updateInput}
                                        name="dob"
                                        setter={setData}
                                    />
                                    :
                                    <InputField                                        
                                        value={data?.dob}
                                        type="date" 
                                        functionPointer={session?.env?.functions?.updateInput}
                                        name="dob"
                                        setter={setData}
                                    />
                                }
                            </div>
                            <div className='gC5 f'>
                                {!obitTabSelected ?
                                        <InputField
                                            value={data?.ssn}
                                            placeholder={viewType !== "full" ? "SSN *" : "Social Security Number *"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="ssn"
                                            setter={setData}
                                            {...(displayInvalid && invalidInputs.includes('ssn') ? { error:' '} : {})}
                                        />
                                    :          
                                        <InputField
                                            value={data?.city}
                                            placeholder={"City"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="city"
                                            setter={setData}
                                        />
                                }
                            </div>
                            {obitTabSelected ?
                                <div className='gC7 f stateDropdown stateInputBox g'>
                                    <div className='gR2'>
                                        <Dropdown 
                                            list={session?.individual.data[session?.handler?.data?.currentAccountID].individualAttributesData?.state.list}
                                            setShowMenu={(input) => {
                                                setSelectedState((prev) => ({ 
                                                    showMenu: input ?? !prev.showMenu, 
                                                }));
                                            }}
                                            default={"State"}
                                            showMenu={selectedState?.showMenu}
                                            valuePointer="state"
                                            onClick={(value)=>{
                                                if (value === "State") {
                                                    setData(prevData => ({
                                                        ...prevData,
                                                        state: '',
                                                    }));
                                                } else {
                                                    setData(prevData => ({
                                                        ...prevData,
                                                        state: value,
                                                    })); 
                                                }
                                                setSelectedState((prev) => ({ ...prev, showMenu: false }));
                                            }}
                                        />
                                    </div>
                                </div>
                                :
                                <div className='gC7 f stateDropdown stateInputBox g'>
                                    <div className='gR2'>
                                    <Dropdown 
                                        list={session?.individual.data[session?.handler?.data?.currentAccountID].individualAttributesData?.state.list}
                                        setShowMenu={(input) => {
                                            setSelectedState((prev) => ({ 
                                                showMenu: input ?? !prev.showMenu, 
                                            }));
                                        }}
                                        default={"State"}
                                        showMenu={selectedState?.showMenu}
                                        valuePointer="state"
                                        onClick={(value)=>{
                                            if (value === "State") {
                                                setData(prevData => ({
                                                    ...prevData,
                                                    state: '',
                                                }));
                                            } else {
                                                setData(prevData => ({
                                                    ...prevData,
                                                    state: value,
                                                })); 
                                            }
                                            setSelectedState((prev) => ({ ...prev, showMenu: false }));
                                        }}
                                    />
                                    </div>
                                </div>
                            }
                            {obitTabSelected &&
                                <div className='gC9 f'>
                                        <InputField
                                            value={data?.zip_code}
                                            placeholder={"Zip Code"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="zip_code"
                                            setter={setData}
                                        />
                                </div>
                            }
                            
                        </div>
                        <div className='cC gR4'>
                            <div 
                                className={`inputSearch btnWIcon ${requiredFilled() ? '' : 'inactive'} cC dP bR p g`}
                                onClick={() => 
                                    { 
                                        if(requiredFilled()){
                                            if (validateInputs()) 
                                            { 
                                                setDisplayInvalid(false);
                                                setInvalidInputs([]);
                                                if(!obitTabSelected){setDeathSearched(true); deathSearch();}else{setObitSearched(true); obitSearch(); setCurrentObitRow(0);} 
                                            }  
                                            else{
                                                setDisplayInvalid(true)
                                            }
                                        }
                                    }}
                            >
                                Search <SearchTwoToneIcon/>
                            </div>
                        </div>
                    </div>

                    <div className='researchHubResults f oA g bR dP gR2'>  
                        { ((!obitTabSelected && !deathSearched && !deathLoading) || (obitTabSelected && !obitSearched && !obitLoading)) ?
                            <div className='empty loading g cC'>
                                {/* <div className='vDivider gR1' style={{width:'3px', height:'100px', backgroundColor:'darkGray'}}></div> */}
                                <div className=''><div className=''><PersonSearchIcon/><div>Search an individual to see results</div></div></div>
                            </div>
                        :
                            <>
                                {(!obitTabSelected && deathSearched && !deathLoading && deathResData !== 0) ?
                                    <div>
                                        {Array.from({ length: deathResData.length }).map((_, index) => (
                                            <div key={index} className='cC dG resultBlock'>
                                                <>
                                                <div className='cC dG'>
                                                    {<PersonIcon/>}
                                                    <div className='bold'>{'Name:'}</div>
                                                    {deathResData[index]?.first_name + " " + deathResData[index]?.middle_name + " " + deathResData[index]?.last_name}
                                                </div>
                                                <div className='cC dG'>
                                                    {<VerifiedIcon/>}
                                                    <div className='bold'>{'Source:'}</div>
                                                    {deathResData[index]?.source}
                                                </div>
                                                <div className='cC dG'>
                                                    {<AssuredWorkloadIcon/>}
                                                    <div className='bold'>{'DOD:'}</div>
                                                    {deathResData[index]?.dod ? session?.env?.functions?.reformatDate(deathResData[index]?.dod) : 'N/A'}
                                                </div>
                                                <div className='cC dG'>
                                                    {<FingerprintIcon/>}
                                                    <div className='bold'>{'SSN:'}</div>
                                                    {deathResData[index]?.ssn}
                                                </div> 
                                                <div className='cC dG'>
                                                    {<CalendarMonthIcon/>}
                                                    <div className='bold'>{'DOB:'}</div>
                                                    {deathResData[index]?.dob ? session?.env?.functions?.reformatDate(deathResData[index]?.dob) : 'N/A'}
                                                </div>                                       
                                                </>
                                            </div>
                                        ))}
                                    </div>
                                :   
                                    (obitTabSelected  && obitSearched && !obitLoading && obitResData.length !== 0) &&
                                        <div className='obituaryResults oA dG g'>
                                            <div className='gC1 obituaryListBox oA f'>
                                                {Array.from({ length: obitResData.length }).map((_, index) => (
                                                    <div key={index} className={`cL dP p g obitResultBlock ${currentObitRow === index ? 'selectedResultBlock' : ''}`} onClick={() => {setCurrentObitRow(index)}}>
                                                        <div className='cC dG gR1 gC1'>
                                                            {<PersonIcon/>}
                                                            <div className='bold'>{"Name: "}</div>
                                                            {obitResData[index]?.first_name + " " + (obitResData[index]?.middle_name ?? "") + " " + obitResData[index]?.last_name}
                                                        </div>
                                                        {(!obitResData[index]?.dod && obitResData[index]?.death_year) ?
                                                        <div className='cC dG gR1 gC2'>
                                                            {<AssuredWorkloadIcon/>}
                                                            <div className='bold'>{'Death Year:'}</div>
                                                            {obitResData[index]?.death_year}
                                                        </div>
                                                        :
                                                        <div className='cC dG gR1 gC2'>
                                                            {<AssuredWorkloadIcon/>}
                                                            <div className='bold'>{'DOD:'}</div>
                                                            {obitResData[index]?.dod ? session?.env?.functions?.reformatDate(obitResData[index]?.dod) : "N/A"}
                                                        </div>
                                                        }
                                                        {(!obitResData[index]?.dob && obitResData[index]?.age) ?
                                                            <div className='cC dG gR1 gC3'>
                                                                {<CalendarMonthIcon/>}
                                                                <div className='bold'>{'Age:'}</div>
                                                                {obitResData[index]?.age}
                                                            </div> 
                                                            :
                                                            <>
                                                            {(!obitResData[index]?.dob && !obitResData[index]?.age && obitResData[index]?.birth_year) ?
                                                            <div className='cC dG gR1 gC3'>
                                                                {<CalendarMonthIcon/>}
                                                                <div className='bold'>{'Birth Year:'}</div>
                                                                {obitResData[index]?.birth_year}
                                                            </div>
                                                            :
                                                            <div className='cC dG gR1 gC3'>
                                                                {<CalendarMonthIcon/>}
                                                                <div className='bold'>{'DOB:'}</div>
                                                                {obitResData[index]?.dob ? session?.env?.functions?.reformatDate(obitResData[index]?.dob) : "N/A"}
                                                            </div>
                                                            }
                                                            </>
                                                        }
                                                        <div className='cC dG gR2 gC1'>
                                                            {<LocationCityIcon/>}
                                                            <div className='bold'>{"City:"}</div>
                                                            {obitResData[index]?.city ?? "N/A"}
                                                        </div>
                                                        <div className='cC dG gR2 gC2'>
                                                            {<LocationOnIcon/>}
                                                            <div className='bold'>{"State:"}</div>
                                                            {obitResData[index]?.state ?? "N/A"}
                                                        </div>
                                                        <div className='cC dG gR2 gC3'>
                                                            {<PushPinIcon/>}
                                                            <div className='bold'>{"Zip:"}</div>
                                                            {obitResData[index]?.zip ?? "N/A"}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='gC2 bR dP dG g f oA obituaryTextBox'>
                                                <div className='bold'>Obituary Details</div>
                                                <div className='cC obitLinkBlock'><a href={obitResData[currentObitRow]?.url} target="_blank" className="link urlStyle bR cC dG"><LanguageIcon/>{getHostName(obitResData[currentObitRow]?.url)}</a></div>
                                                <div className='obitContentBlock dP'>
                                                    <div className='cC dG gR1'> <ArticleIcon/> <div className='f bold cL'>Content:</div> </div> 
                                                    <div className="obitTextContent" ref={obitString}>{obitResData[currentObitRow]?.text_content}</div>
                                                </div>
                                            </div>
                                        </div>
                                }
                                {( !obitLoading && !deathLoading && ((obitSearched && obitTabSelected && obitResData.length === 0) || (deathSearched && !obitTabSelected && deathResData.length === 0)) ) ?
                                    <>
                                        <div className='empty g cC'>
                                            <div className=''><div className=''><SearchOffIcon/><div>No results found! More specifications may be required.</div></div></div>
                                        </div>
                                    </>
                                :
                                    <></>
                                }
                            </>
                        }

                        {(!obitTabSelected && deathLoading) && 
                            <div className='cC'>
                                <CircularProgress disableShrink color="success" size={60}/>
                            </div>
                        }
                        {(obitTabSelected && obitLoading) &&
                            <div className='cC'>
                                <CircularProgress disableShrink color="success" size={60}/>
                            </div>
                        }

                    </div>

                </div>
            </div>
    )
}

export default ResearchHub;

{/* <div className='g gR2 gC2 participantBlock dP'>
                        <div className='bold cL gR1 dP dG'><PersonIcon/>Individual Information <span style={{ color: 'red' }}>*</span></div> 
                        <div className='g nameSection gR2 dG'>
                            
                            <div className='suffixInputSize'>
                                <InputField
                                    value={data?.suffix}
                                    placeholder={"Suffix"}
                                    functionPointer={session?.env?.functions?.updateInput}
                                    name="suffix"
                                    setter={setData}
                                />
                            </div>
                        </div>  
                        <div className='g genderSection gR3 dG'>
                            {/* <div className='genderBox g'>         
                                    {data?.gender ?<div className="genderLabel inputFont cL gR1" htmlFor="gender-select">Gender</div> : <div className="genderLabel"> </div>}
                                    <select 
                                        className='genderField gR2'
                                        id="gender-select" 
                                        value={data?.gender} 
                                        onChange={handleGenderChange}
                                        // If gender is not selected, show the placeholder
                                        style={data.gender ? {} : { color: 'grey' }}
                                    >
                                        {!data.gender && <option value="" disabled >Gender *</option>}
                                        <option style={{ color:'black'}} value="Male">Male</option>
                                        <option style={{ color:'black'}} value="Female">Female</option>
                                    </select>
                            </div> */}
                        //     <div className='inputSize'>
                        //         
                        //     </div>
                        //     <div className='inputFont bC dobLabel'>{viewType === "mobile" ? "DOB*:" : "Date of Birth *:"}</div>
                        //     <div className='g f dobField'>
                        //         
                        //         <InputField                                        
                        //             value={data?.dob}
                        //             type="date" 
                        //             functionPointer={session?.env?.functions?.updateInput}
                        //             name="dob"
                        //             setter={setData}
                        //         />
                        //     </div>
                        // </div>
                        // <div className='g locationSection gR4 dG'>
                        //     <InputField
                        //         value={data?.city}
                        //         placeholder={"City *"}
                        //         functionPointer={session?.env?.functions?.updateInput}
                        //         name="city"
                        //         setter={setData}
                        //     />
                        //     <div className='g'>
                        //         <div className='gR1' style={{height:'32px'}}>
                        //             {data.state === "State *" || data.state === "" ? <div className='f'></div> : <div className='f cL dP inputFont'>State</div>}
                        //         </div>
                        //         <div className='gR2 stateDropdown' style={{backgroundColor:'white'}}> 
                        //             <Dropdown 
                        //                 list={session?.individual.data[session?.handler?.data?.currentAccountID].individualAttributesData?.state.list}
                        //                 setShowMenu={(input) => {
                        //                     setSelectedState((prev) => ({ 
                        //                         showMenu: input ?? !prev.showMenu, 
                        //                     }));
                        //                 }}
                        //                 default={"State *"}
                        //                 showMenu={selectedState?.showMenu}
                        //                 valuePointer="state"
                        //                 onClick={(value)=>{ 
                        //                     setData(prevData => ({
                        //                         ...prevData,
                        //                         state: value
                        //                     }));  
                        //                     setSelectedState((prev) => ({ ...prev, showMenu: false }));
                        //                 }}
                        //             />
                        //         </div>
                        //     </div>
                            {/* <InputField
                                value={data?.state}
                                placeholder={"State *"}
                                functionPointer={session?.env?.functions?.updateInput}
                                name="state"
                                setter={setData}
                            /> */}
                    //         
                    //     </div>
                    // </div>     */}
import React, { useEffect, useState } from 'react';

import PlaceIcon from '@mui/icons-material/Place';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import NewspaperIcon from '@mui/icons-material/Newspaper';

function SessionIndividual(props){

  const individualAttributesData = {
    abl_first_name: {
      formType: 'string',
      friendlyTerm: 'ABL First Name',
      stems: ["deceased"],
      searchable : false,
      altColor : true,
    },
    abl_dob: {
      formType: 'date',
      friendlyTerm: 'ABL DOB',
      mobileFriendlyTerm: "ABL DOB",
      returnType: 'date',
      stems: ["deceased"],
      searchable : false,
      altColor : true,
      title: 'ABL Date of Birth',
    },
    abl_dod: {
      formType: 'date',
      friendlyTerm: 'ABL DOD',
      mobileFriendlyTerm: "ABL DOD",
      returnType: 'date',
      stems: ["deceased"],
      searchable : false,
      altColor : true,
      title: 'ABL Date of Death'
    },
    abl_last_name: {
      formType: 'string',
      friendlyTerm: 'ABL Last Name',
      stems: ["deceased"],
      searchable : false,
      altColor : true,
    },
    abl_middle_name: {
      formType: 'string',
      friendlyTerm: 'ABL Middle Name',
      stems: ["deceased"],
      searchable : false,
      altColor : true,
    },
    abl_ssn: {
      formType: 'string',
      friendlyTerm: 'ABL SSN',
      mobileFriendlyTerm: "ABL SSN",
      stems: ["deceased"],
      searchable : false,
      altColor : true,
      title: 'ABL Social Security Number',
    },
    abl_city: {
      formType: 'string',
      friendlyTerm: 'ABL City',
      stems: ["deceased"],
      searchable : false,
      altColor : true,
    },
    abl_state: {
      formType: 'string',
      friendlyTerm: 'ABL State',
      stems: ["deceased"],
      searchable : false,
      altColor : true,
    },
    abl_source: {
      formType: 'dropdown',
      friendlyTerm: 'ABL Source',
      stems: ["deceased"],
      list: ["SSA", "State", "Obituary"],
      altColor : true,
    },
    abl_zip_code: {
      formType: 'string',
      friendlyTerm: 'ABL Zip Code',
      stems: ["deceased"],
      searchable : false,
      altColor : true,
    },
    account_id: {
      formType: 'int',
      friendlyTerm: 'Account ID',
      stems: [],
    },
    city: {
      formType: 'string',
      friendlyTerm: 'City',
      stems: ["deceased", "individuals"],
    },
    death_confirmation_date: {
      formType: 'date',
      friendlyTerm: 'Confirmed Date',
      returnType: 'date',
      stems: ["deceased", "individuals"],
      altColor: true,
      title: 'Confirmation Date',
    },
    dob: {
      formType: 'date',
      friendlyTerm: 'Date of Birth',
      returnType: 'date',
      stems: ["deceased", "individuals"],
      editable : true,
    },
    dod: {
      formType: 'date',
      friendlyTerm: 'Date of Death',
      returnType: 'date',
      stems: ["deceased", "individuals"],
    },
    disabled: {
      formType: 'dropdown',
      friendlyTerm: 'Disabled',
      list: ['true', 'false'],
      stems: [],
    },
    email: {
      formType: 'string',
      friendlyTerm: 'Email',
      stems: [],
    },
    gender: {
      formType: 'dropdown',
      friendlyTerm: 'Gender',
      list: ['Male', 'Female'],
      stems: [],
    },
    first_name: {
      formType: 'string',
      friendlyTerm: 'First Name',
      stems: ["deceased", "individuals"],
    },
    individual_id: {
      formType: 'int',
      friendlyTerm: 'ID',
      stems: ["deceased", "individuals"],
    },
    internal_id: {
      formType: 'string',
      friendlyTerm: 'Internal ID',
      stems: ["deceased", "individuals"],
      editable: true,
    },
    internal_group: {
      formType: 'generatedList',
      friendlyTerm: 'Group',
      alternateTerm: 'Internal Group',
      stems: ["deceased", "individuals"],
      list: {},
      editable : true,
    },
    last_name: {
      formType: 'string',
      friendlyTerm: 'Last Name',
      stems: ["deceased", "individuals"],
    },
    last_login: {
      formType: 'string',
      friendlyTerm: 'Last Login',
      stems: [],
    },
    middle_name: {
      formType: 'string',
      friendlyTerm: 'Middle Name',
      stems: ["deceased", "individuals"],
    },
    phone_number:{
      formType: 'string',
      friendlyTerm: 'Phone Number',
      stems: [],
    },
    suffix: {
      formType: 'string',
      friendlyTerm: 'Suffix',
      stems: ["deceased", "individuals"],
    },
    record_id: {
      formType: 'int',
      friendlyTerm: 'Record ID',
      stems: [],
    },
    ssn: {
      formType: 'string',
      friendlyTerm: 'SSN',
      mobileFriendlyTerm: "SSN",
      stems: ["deceased", "individuals"],
      title: 'Social Security Number',
    },
    // source: {
    //   formType: 'dropdown',
    //   friendlyTerm: 'Source',
    //   list: ["SSA", "State", "Obits"],
    //   stems: ["deceased", "individuals"],
    // },
    state: {
      formType: 'dropdown',
      friendlyTerm: 'State',
      list: [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
        'Connecticut', 'Delaware', 'DC', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
        'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
        'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
        'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada',
        'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
        'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
        'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
        'West Virginia', 'Wisconsin', 'Wyoming', 
      ],
      stems: ["deceased", "individuals"],
      editable : true,
    },
    zip_code: {
      formType: 'string',
      friendlyTerm: 'Zip Code',
      stems: ["deceased", "individuals"],
    },
    benef_first_name: {
      formType: 'string',
      friendlyTerm: 'Beneficiary First Name',
      stems: [],
    },
    benef_middle_name: {
      formType: 'string',
      friendlyTerm: 'Beneficiary Middle Name',
      stems: [],
    },
    benef_last_name: {
      formType: 'string',
      friendlyTerm: 'Beneficiary Last Name',
      stems: [],
    },
    benef_dob: {
      formType: 'string',
      friendlyTerm: 'Beneficiary DOB',
      returnType: 'date',
      stems: [],
    },
    benef_city: {
      formType: 'string',
      friendlyTerm: 'Beneficiary City',
      stems: [],
    },
    benef_state: {
      formType: 'string',
      friendlyTerm: 'Beneficiary State',
      list: [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
        'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
        'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
        'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
        'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada',
        'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
        'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
        'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
        'West Virginia', 'Wisconsin', 'Wyoming',
      ],
      stems: [],
    },
    benef_zip_code: {
      formType: 'string',
      friendlyTerm: 'Beneficiary Zip',
      stems: [],
    },
    benef_relationship: {
      formType: 'string',
      friendlyTerm: 'Beneficiary Relationship',
      list: [
        'Spouse', 'Parent', 'Child', 'Sibling', 'Other'
      ],
      stems: [],
    },
  };

  // const sourceData = {
  //   SSA: {
  //     friendlyTerm: "SSA",
  //     backgroundColor: "#C694F2", // Lighter Purple
  //     fontColor: "#59298B", // More Vibrant Font Color
  //     hoveredBackgroundColor: "#AE69D6", // More Vibrant Purple
  //     hoveredFontColor: "#4A148C", // Even Darker Font Color
  //     icon: <PlaceIcon />,
  //   },
  //   State: {
  //     friendlyTerm: "State",
  //     backgroundColor: "#ffccbc", // Lighter Orange
  //     fontColor: "#ff5722", // More Vibrant Font Color
  //     hoveredBackgroundColor: "#ffab91", // More Vibrant Orange
  //     hoveredFontColor: "#bf360c", // Even Darker Font Color
  //     icon: <AssuredWorkloadIcon />,
  //   },
  //   Obits: {
  //     friendlyTerm: "Obituary",
  //     backgroundColor: "#A5B7D3", // Lighter Blue
  //     fontColor: "#214F83", // More Vibrant Font Color
  //     hoveredBackgroundColor: "#76A2CC", // More Vibrant Blue
  //     hoveredFontColor: "#005EB8", // Even Darker Font Color
  //     icon: <NewspaperIcon />,
  //   },
  // };

  const sourceData = {
    SSA: {
      friendlyTerm: "SSA",
      backgroundColor: "#7ca295", // Lighter Purple
      fontColor: "#104735", // More Vibrant Font Color
      hoveredBackgroundColor: "#5f7c72", // More Vibrant Purple
      hoveredFontColor: "#09281e", // Even Darker Font Color
      icon: <AssuredWorkloadIcon />,
    },
    State: {
      friendlyTerm: "State",
      backgroundColor: "#c3d1cc", // Lighter Orange
      fontColor: "#719085", // More Vibrant Font Color
      hoveredBackgroundColor: "#9ca9a5", // More Vibrant Orange
      hoveredFontColor: "#556962", // Even Darker Font Color
      icon: <PlaceIcon />,
    },
    Obituary: {
      friendlyTerm: "Obituary",
      backgroundColor: "#f3e3c3", // Lighter Blue
      fontColor: "#d2ab66", // More Vibrant Font Color
      hoveredBackgroundColor: "#c0b49b", // More Vibrant Blue
      hoveredFontColor: "#a2844f", // Even Darker Font Color
      icon: <NewspaperIcon />,
    },
  };
  
  const attributeData = {
    ownerSitusState: {
      formType: 'dropdown',
      list: [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
        'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
        'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
        'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
        'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada',
        'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
        'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
        'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
        'West Virginia', 'Wisconsin', 'Wyoming',
      ],
      friendlyTerm: 'Owner Situs State',
    },
  };

  const [data, setData] = useState({
    initialized: false,
    deathTrac: {
      deceased: undefined,
    },
    attributeData: individualAttributesData,
    individualAttributesData: individualAttributesData,
    sourceData: sourceData
  });

  // useEffect(() => {
  //   console.log("individualData", data);
  // },[data]);

  const initializeData = (accountNumbers) => {
    const newData = {};
  
    accountNumbers.forEach((accountNumber) => {
      newData[accountNumber] = {
        deathTrac: {
          deceased: undefined,
        },
        attributeData: individualAttributesData,
        individualAttributesData,
        sourceData,
      };
    });
  
    setData(newData);
  };

  const updateIndividual = (path, newArray) => {
    setData((prevData) => {
      const newData = { ...prevData };

      // Split the path into segments
      const pathSegments = path.split('.');
      let current = newData;

      // Function to handle nested objects or arrays
      const createNested = (segments) => {
        if (segments.length === 1) {
          return Array.isArray(newArray) ? [] : {};
        }
        return createNested(segments.slice(1));
      };

      pathSegments.forEach((segment, index) => {
        if (segment.includes('[') && segment.endsWith(']')) {
          // This segment indicates an array
          const arraySegment = segment.split('[')[0];
          const key = segment.match(/\[(.*?)\]/)[1];

          if (!current[arraySegment]) {
            current[arraySegment] = [];
          }

          if (index === pathSegments.length - 1) {
            // This is the last segment, so update the array
            current[arraySegment][key] = newArray;
          } else {
            // Not the last segment, create nested objects or arrays
            if (!current[arraySegment][key]) {
              current[arraySegment][key] = createNested(pathSegments.slice(index + 1));
            }
            current = current[arraySegment][key];
          }
        } else {
          // This segment indicates an object
          if (!current[segment]) {
            current[segment] = {};
          }

          if (index === pathSegments.length - 1) {
            // This is the last segment, so update the object
            current[segment] = newArray;
          } else {
            // Not the last segment, continue with the object
            current = current[segment];
          }
        }
      });

      return newData;
    });
  };

  function updateAttributeData(obj, type, session) {
    // Create a shallow copy of the data object
    const newData = { ...data };
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj?.[key];
        const targetType = obj?.type ?? type;
        if (newData[session?.handler?.data?.currentAccountID]?.attributeData?.[key]?.formType === "generatedList") {
          // Check if the list exists in attributeData
          if (!newData[session?.handler?.data?.currentAccountID]?.attributeData[key].list[targetType]) {
            newData[session?.handler?.data?.currentAccountID].attributeData[key].list[targetType] = {}; // Create an empty object for the targetType if it doesn't exist
          }

          if (!newData[session?.handler?.data?.currentAccountID]?.attributeData?.[key]?.list?.[targetType]?.[value]) {
            newData[session?.handler?.data?.currentAccountID].attributeData[key].list[targetType][value] = { count: 1 }; // Create an empty object if it doesn't exist
          } else {
            newData[session?.handler?.data?.currentAccountID].attributeData[key].list[targetType][value].count++; // Increment the count if it exists
          }
        }
      }
    }

    // Update the entire data object with the modified attributeData
    setData(newData);
  }

  function importGeneratedList(list, attr, stem, session) {
    // Ensure stem is provided
    if (!stem) {
      console.error('Stem is required for importGeneratedList');
      return;
    }
  
    // Create a shallow copy of the data object
    const newData = { ...data };
  
    // Initialize the stem object if it doesn't exist
    newData[session?.handler?.data?.currentAccountID].attributeData[attr].list[stem] = newData[session?.handler?.data?.currentAccountID]?.attributeData[attr].list[stem] || {};
  
    // Transform the list array into the desired object format
    list.forEach(item => {
      newData[session?.handler?.data?.currentAccountID].attributeData[attr].list[stem][item.name] = { count: item.count };
    });
  
    // Update the state with the new data
    setData(newData);
  }
  

  function calculateTermData(individualsList, startDate, endDate, group){
    const specifiedGroup = group === "All Groups" ? undefined : group;

    let totalAge = 0;
    let deathsPerMonth = {}; // Dictionary to store deaths count per month

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const deceasedData = {
      statistics : {},
      sourceCount: {},
      ageOfDeaths: [],
      reportedDeaths: {
          currentMonthCount: 0,
          currentYearCount: 0,
          averageDeathsPerMonth : 0,
          totalCount: 0,
      },
      termDeaths: {
        currentMonthCount: 0,
        currentYearCount: 0,
        averageDeathsPerMonth : 0,
        totalCount: 0,
      }
    };

    if (startDate && endDate) {
      individualsList = individualsList.filter((individual) => {
        return individual?.death_confirmation_date >= startDate && individual?.death_confirmation_date <= endDate;
      });
    }

    if(specifiedGroup){
      individualsList = individualsList.filter((individual) => {
        return individual.internal_group === specifiedGroup;
      });
    }

    const earliestDeathYear = deceasedData.ageOfDeaths.length > 0 ? new Date(individualsList?.[0]?.death_confirmation_date).getFullYear() : currentYear;
    const totalMonths = (currentYear - earliestDeathYear) * 12 + currentMonth - 1; // Subtract 1 to account for the current partial month
    deceasedData["reportedDeaths"].totalMonths = totalMonths; // Store total months for reference
    deceasedData["reportedDeaths"].averageDeathsPerMonth = totalMonths > 0 ? deceasedData["reportedDeaths"].totalCount / totalMonths : 0;
    deceasedData["reportedDeaths"].averageDeathsPerMonth = parseFloat(deceasedData["reportedDeaths"].averageDeathsPerMonth.toFixed(1));

    function calculateAge(birthDate, referenceDate) {
      if(birthDate === undefined || referenceDate === undefined){
        return undefined
      }

      let age = referenceDate.getFullYear() - birthDate.getFullYear();
      const monthDiff = referenceDate.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && referenceDate.getDate() < birthDate.getDate())) {
          age--;
      }
      return age;
    }

    function calculateStatistics(ages) {
      // Mean
      const mean = ages.reduce((acc, age) => acc + age, 0) / ages.length;
  
      // Median
      ages.sort((a, b) => a - b);
      let median;
      if (ages.length % 2 === 0) {
          median = (ages[ages.length / 2 - 1] + ages[ages.length / 2]) / 2;
      } else {
          median = ages[Math.floor(ages.length / 2)];
      }
  
      // Mode
      let modeMap = {};
      let maxFreq = 0;
      let modes = [];
      ages.forEach(age => {
          modeMap[age] = (modeMap[age] || 0) + 1;
          if (modeMap[age] > maxFreq) {
              maxFreq = modeMap[age];
              modes = [age];
          } else if (modeMap[age] === maxFreq) {
              modes.push(age);
          }
      });
      modes = [...new Set(modes)]; // Ensure modes are unique
  
      return { mean, median, modes };
    }

    individualsList.forEach(individual => {
        const deathDate = individual?.dod ? new Date(individual.dod) : undefined;
        const [docYear, docMonth, docDay] = (individual?.death_confirmation_date || '').split('-');
        const dateOfConfirmation = new Date(docYear, docMonth - 1, docDay);
        const year = dateOfConfirmation.getFullYear();
        const month = dateOfConfirmation.getMonth() + 1;
        const birthDate = individual.dob ? new Date(individual?.dob) : undefined;
        const ageAtDeath = calculateAge(birthDate, deathDate);

        if(ageAtDeath){
          deceasedData.ageOfDeaths.push(ageAtDeath);
          totalAge += ageAtDeath;
        }

        if (year === currentYear){
            deceasedData["reportedDeaths"].currentYearCount++;
        }

        if(year === currentYear && month === currentMonth) {
            deceasedData["reportedDeaths"].currentMonthCount++;
        }
  
        deceasedData["reportedDeaths"].totalCount++;
        deceasedData.sourceCount[individual?.source] = (deceasedData?.sourceCount?.[individual?.source] || 0) + 1;

        const deathMonthYear = `${dateOfConfirmation.getFullYear()}-${dateOfConfirmation?.getMonth() + 1}`;
        deathsPerMonth[deathMonthYear] = (deathsPerMonth?.[deathMonthYear] || 0) + 1;
    });

    const statistics = calculateStatistics(deceasedData?.ageOfDeaths);
    deceasedData.statistics = statistics;
    deceasedData.ageOfDeaths.sort((a, b) => a - b);
    
    return deceasedData;
  }

  function uploadOne(session, paramVals){
    session?.env?.functions?.buildFetchRequest("individual/addOne", paramVals)
      .then(response => response.json())
      .then(resData => {
        
        if(resData.status === 200){

        }else{
            //setErrorStatus(resData.message);
        }
    });
  }

  const functions = {
    updateAttributeData,
    calculateTermData,
    uploadOne,
    importGeneratedList,
    initializeData,
  }

  const sessionIndividual = {
    data,
    setData : updateIndividual,
    functions,
  }

  return sessionIndividual;
};

export default SessionIndividual;
import EnvVars from './EnvVars.js';
import SessionUser from './SessionUser.js';
import SessionAccount from './SessionAccount.js';
import SessionIndividual from './SessionIndividual.js';
import ShareLink from './ShareLink.js';
import Notifications from './Notifications.js';
import ReportingVars from './ReportingVars.js';
import DashboardVars from './DashboardVars.js';
import UploadVars from './UploadVars.js';
import EditSheetVars from './EditSheetVars.js';
import SessionHandler from './SessionHandler.js';
import ResearchHub from '../LifeTracPortal/ResearchHub.js';

function SessionVars(props){
    const user = SessionUser();
    const account = SessionAccount();
    const individual = SessionIndividual();
    const shareLink = ShareLink();
    const notifications = Notifications();
    const reporting = ReportingVars();
    const dashboard = DashboardVars();
    const env = EnvVars(user);
    const upload = UploadVars();
    const edit = EditSheetVars();
    const handler = SessionHandler(account);
    const research = ResearchHub();

    const sessionVars = {
        env,
        user,
        account,
        individual,
        reporting,
        dashboard,
        shareLink,
        notifications,
        upload,
        edit,
        handler,
        research,
    }

    return sessionVars;
};

export default SessionVars;
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from "@chakra-ui/react";

import SessionVars from './SessionVars/SessionVars.js';
import Initialization from './SessionVars/Initialization.js';
import NavBar from './NavBar/NavBar.js';
import LifeTracPortal from './LifeTracPortal/LifeTracPortal.js';
import Login from './Public/Login.js';
import Rerouter from './Public/Rerouter.js';
import Unsubscribe from './Public/Unsubscribe.js';
import OverlayHandler from "./Components/Overlays/OverlayHandler.js";
import AssuredWorkloadTwoToneIcon from '@mui/icons-material/AssuredWorkloadTwoTone';
import CalculateTwoToneIcon from '@mui/icons-material/CalculateTwoTone';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';

function App() {
  const session = SessionVars();
  const initialize = Initialization();

  const initializeCatch = {
    accountSetup : 0,
    allDeceased : 0,
    reportingData : 0,
    allGroups : 0,
  }

  function verifyInitialization(application) {
    const requiredInitialization = {
      "deathTrac" : ["allDeceased", "reportingData"],
      "lifeTrac" : []
    }

    const requiredLoaders = requiredInitialization?.[application];
    if (!requiredLoaders) {
      return true;
    }

    for (const loader of requiredLoaders) {
      if (initialize?.loadHandler?.[loader] !== true) {
        return true;
      }
    }

    return false;
  }

  const userFunctions = session?.user?.functions;
  const viewType = session?.env?.viewport?.viewType;

  useEffect(() => {
    if(!session?.user?.data?.sessionToken && !localStorage.getItem("sessionUser")){
      session?.env?.setOverlay();
    }

    

    if(session?.user?.data?.sessionToken){
      //if(initializeCatch?.accountSetup === 0){
        // initializeCatch.accountSetup++;
        
        
      //}

          // Check if accounts are Life Trac accounts
      let newAccountID;
      let newAccountName;
      newAccountID = session?.handler?.functions?.findAccountIdByName(session?.user?.data?.companyName, session?.account?.data);
      newAccountName = session?.user?.data?.companyName;
      if(session?.account?.data[newAccountID]?.type !== 'LifeTrac'){
        for (const key in session?.account?.data) {
          if (session?.account?.data.hasOwnProperty(key)){
            if(session?.account?.data[key]?.type === "LifeTrac"){
              newAccountID = session?.account?.data[key]?.accountID;
              newAccountName = session?.account?.data[key]?.name;
              break;
            }
          }
        }
      }
      if(session?.handler?.data?.currentAccountID === ''){
        session?.handler?.setData({currentAccountID: newAccountID, currentName : newAccountName});
      }
      if(session?.reporting?.data?.initialized === false){
        session?.reporting?.functions?.initializeData(Object.keys(session?.account?.data));
      }
      if(session?.individual?.data?.initialized === false){
        session?.individual?.functions?.initializeData(Object.keys(session?.account?.data));
      }
      if(session?.user?.data?.sessionToken){
        if(newAccountID !== null && newAccountID !== ''){
          if(!initialize?.loadHandler?.reportingData && initializeCatch?.reportingData === 0){
            initializeCatch.reportingData++;
            // initialize?.loadReportingData(session, newAccountID);
          }
    
          if(!initialize?.loadHandler?.allDeceased && initializeCatch?.allDeceased === 0){
            initializeCatch.allDeceased++;
            //initialize?.loadDeceased(session, newAccountID);
          }
    
          if(!initialize?.loadHandler?.allGroups && initializeCatch?.allGroups === 0){
            initializeCatch.allGroups++;
            initialize?.loadAllGroups(session);
          }
        }
      }
      
      // newAccountID = session?.handler?.functions?.findAccountIdByName(session?.user?.data?.companyName, session?.account?.data);
   
    }
  }, [session?.user?.data]);


  // useEffect(() => {  
    
  // }, [session?.handler?.data]);

  useEffect(() => {
    const storedSessionUser = JSON.parse(window.localStorage.getItem('sessionUser'));
    if (storedSessionUser) {
      session?.user?.functions?.maintainSession(session?.env?.functions?.buildFetchRequest);
    }

  }, []);
  
  // useEffect(() => {
  //   const type = session?.user?.data?.currentActivity;
  //   const isTimeline = type === "timeline";
  //   const isMessages = type === "messages";
  //   const isDetails = type === "details" && viewType !== "full";

  //   if(!isMessages){
  //     window.scrollTo({ top: 0, behavior: 'auto' });
  //   }
  //   let updatedNotifications = session?.notifications?.data?.filter(
  //     (notificationArray) =>
  //       !(
  //         (isTimeline && notificationArray[1]?.activityType === "Status" &&
  //           notificationArray[1]?.relatedID === currentPolicy?.relatedPolicyID) ||
  //         (isMessages && notificationArray[1]?.activityType === "Message" &&
  //           notificationArray[1]?.relatedID === currentPolicy?.relatedPolicyID) ||
  //         (isDetails && notificationArray[1]?.activityType === "Bid" &&
  //           notificationArray[1]?.relatedID === currentPolicy?.relatedPolicyID)
  //       )
  //   );

  //   session?.notifications?.functions?.loadNotifications(updatedNotifications);
  //   //Need to add conditions to update just timeline if need be, and or bids
  //   //Dependent on viewType and current activity
  //   //updating notifications for bids is dynamic
  //   if (session.notifications?.data?.length !== updatedNotifications?.length) {
  //     const paramVals = {
  //       type: "policy",
  //       relatedID: currentPolicy.relatedPolicyID,
  //       unread: false,
  //     };

  //     session?.env?.functions?.buildFetchRequest("updateReadStatus", paramVals)
  //       .then(response => response.json())
  //       .then(resData => {});
  //   }
  // }, [currentPolicy, session?.user?.data]);

  return (
    <Router>
      {
        session?.user?.data?.sessionToken || JSON.parse(localStorage.getItem("sessionUser") || "{}").sessionToken !== undefined ?
          <>
            <OverlayHandler
              session={session}
              overlay={session?.env?.overlay}
              setter={session?.env?.setOverlay}
              handleClose={session?.env?.functions?.handleClose}
            />
            <div
              className={`App f g oH fC${viewType === "full" ? " cC" : ''}`}
              onClick={()=>{
                session?.user?.functions?.maintainSession();
              }}
            >
              <NavBar
                session={session}
                initialize={initialize}
                loading={verifyInitialization("deathTrac")}
              />
              {(verifyInitialization("deathTrac")) ?
                <div className="loadingScreen f cC">
                  <div className="loader cC g">
                      <div className="cC g fR">
                          <div className="icon cC">
                              <CalculateTwoToneIcon/>
                          </div>
                          <div className="icon cC">
                              <HandshakeTwoToneIcon/>
                          </div>
                          <div className="icon cC">
                              <AssuredWorkloadTwoToneIcon/>
                          </div>
                      </div>
                      <div className="cC">
                          We're gathering your account information.
                      </div>
                  </div>
                </div>
              :
                <LifeTracPortal
                  session={session}
                  initialize={initialize}
                />
              }
            </div>
          </>
        :
          <Routes>
            <Route
                path="/"
                element={
                <ChakraProvider>
                  <Login
                    session={session}
                    initialize={initialize}
                  />
                </ChakraProvider>
              }
            />
            <Route
                path="/unsubscribe"
                element={
                <Unsubscribe
                  session={session}
                />
              }
            />
            <Route
              path="/*"
              element={
                <Rerouter/>
              }
            />
          </Routes>
      }
    </Router>
  );
}

export default App;
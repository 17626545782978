import React from 'react';
import { Link } from 'react-router-dom';

function PercentageBar(props){
    const session = props?.session;
    const individualData = session?.individual?.data;

    const calculatePercentage = (n, min, max) => {
        if (
            [n, min, max].some(val => typeof val !== 'number' || Number.isNaN(val)) ||
            max === min
        ) {
            return n === 0 ? 0 : 100;
        }

        return Math.max(0, Math.min(100, ((n - min) / (max - min)) * 100));
    };

    const renderRow = (data) => {
        const { dataSet, prompt, n, directValue, directMin, directMax, idle } = data;
        const stats = data?.value === "modes" && n !== undefined ? dataSet?.statistics?.[data?.value]?.[n] : dataSet?.statistics?.[data?.value];
        const value = isNaN(stats) ? directValue ?? 0 : parseFloat(stats?.toFixed(1));

        const range = dataSet?.ageOfDeaths;
        const noRange = !range?.length && !(directMin && directMax);
        const lowest = noRange ? '' : (directMin ?? range?.[0] ?? 0);
        const highest = noRange ? '' : (directMax ?? range?.[range?.length - 1] ?? 0);

        const progressStyle = {
            width: `${calculatePercentage(value, lowest, highest)}%`
        };

        return (
            <div className="row cC g dG fC f" key={prompt}>
                <div className="f cC bold prompt gCW">
                    {prompt}
                </div>
                <div className={`percentageBar f g cC dG${noRange ? " inactive" : ''}${idle ? " inactive" : ''}`}>
                    <div className="f cC bold oH">
                        <div className="gCW">
                            {lowest?.toLocaleString()}
                        </div>
                    </div>
                    <div className="bar f g oH" style={{}}>
                        <div className="progress g bold cR" style={progressStyle}>
                            <div className="point">
                                {value?.toLocaleString()}
                            </div>
                        </div>
                    </div>
                    <div className="f cC bold oH">
                        <div className="gCW">
                            {highest?.toLocaleString()}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            {Object.entries(props?.rowData).map(
                ([category]) => renderRow(props?.rowData[category]))
            }
        </>
    );
}

export default PercentageBar;